import chatImg from "@/assets/images/landing/inspection/features/test.png";
import analyticsImg from "@/assets/images/landing/inspection/features/2.png";
import bellImg from "@/assets/images/landing/inspection/features/presentation.png";
import tasksImg from "@/assets/images/landing/inspection/features/chat.png";
import calendarImg from "@/assets/images/landing/inspection/features/shield.png";
import addGroupImg from "@/assets/images/landing/inspection/features/headset.png";
import headsetImg from "@/assets/images/landing/inspection/features/add-group.png";
import shieldImg from "@/assets/images/landing/inspection/features/last.png";
import logo1 from "@/assets/images/landing/inspection/logo/iso copy.png";
import logo11 from "@/assets/images/landing/warranty/step.png";
import logo12 from "@/assets/images/landing/warranty/step2.png";
import logo13 from "@/assets/images/landing/warranty/step3.png";
import logo14 from "@/assets/images/landing/warranty/step4.png";
import logo2 from "@/assets/images/landing/inspection/logo/tuv - white.png";
import logo3 from "@/assets/images/landing/inspection/logo/logo3.png";
import googleImg from "@/assets/images/landing/brands/google.svg";
import zoomImg from "@/assets/images/landing/brands/zoom.svg";
import slackImg from "@/assets/images/landing/brands/slack.svg";
import gmaillImg from "@/assets/images/landing/brands/gmail.svg";
import trelloImg from "@/assets/images/landing/brands/trello.svg";
import mailchimpImg from "@/assets/images/landing/brands/mailchimp.svg";
import dropboxIMg from "@/assets/images/landing/brands/dropbox.svg";
import evernoteImg from "@/assets/images/landing/brands/evernote.svg";
import avatar14Img from "@/assets/images/avatar/14.jpg";
import avatar13Img from "@/assets/images/avatar/13.jpg";
import avatar11Img from "@/assets/images/avatar/11.jpg";
import useCase1Img from "@/assets/images/landing/inspection/use-cases/01.png";
import useCase2Img from "@/assets/images/landing/inspection/use-cases/02.png";
import useCase3Img from "@/assets/images/landing/inspection/use-cases/03.png";
import useCase4Img from "@/assets/images/landing/inspection/use-cases/04.png";
import StarIcon from "@iconify/icons-bx/bx-star";
import BriefcaseAlt2ICon from "@iconify/icons-bx/bx-briefcase-alt-2";
import RocketIcon from "@iconify/icons-bx/bx-rocket";
import BatChartAlt2Icon from "@iconify/icons-bx/bx-bar-chart-alt-2";

import type {
  WorkType,
  FeatureType,
  TestimonialType,
  WorkToolType,
  UseCaseType,
} from "./type";

export const featuresData: FeatureType[] = [
  {
    image: chatImg,
    title: "شمولية الفحص",
    title_en: "Comprehensive Inspection",
    description:
      "تفاصيل عديدة ما بين نقاط خارج المركبة و داخل المركبة بالاضافة الى جميع تفاصيل محركات المركبة كما يشمل فحص كارترست البوم صور للمركبة ",
    description_en:
      "Numerous details ranging from exterior and interior inspection points to all engine details. The CarTrust inspection also includes a photo album of the vehicle.",
  },
  {
    image: addGroupImg,
    title_arabic: "التاكد من سلامة المركبة",
    title: "Ensuring Vehicle Safety",
    descriptione_arabic:
      "تقرير كارترست يوفر المعلومات اللازمة التي تضمن سلامة المركبة و قابليتها للاستخدام حيث يبرز التقرير النقاط عالية الخطورة التي تهدد سلامة  المركبة",
    description:
      "The CarTrust report provides essential information to ensure the vehicle's safety and usability, highlighting high-risk points that threaten vehicle safety.",
  },
  {
    image: headsetImg,
    titlee_arabic: "الاصلاحات المقترحة",
    title: "Recommended Repairs",
    descriptione_arabic:
      "يقترح فنيو الفحص في كارترست بعض الإصلاحات اللازمة للأجزاء التي تظهر عليها أضرار . يمكن أن تشمل هذه الإصلاحات إصلاحات ميكانيكية، أو تغيير قطع الغيار ",
    description:
      "CarTrust inspectors suggest necessary repairs for parts showing damage. These can include mechanical fixes or parts replacement.",
  },
  {
    image: calendarImg,
    titlee_arabic: "التأهيل لبرنامج لضمان الاعطال",
    title: "Qualification for Fault Warranty Program",
    descriptione_arabic:
      "في حال اجتازت المركبة الفحص بنجاح تصبح مؤهلة للانضمام لبرنامج ضمان الاعطال الذي يوفر على العميل و يمنحه راحة البال عن شراء المركبة المستخدمة",
    description:
      "If the vehicle passes the inspection successfully, it qualifies for the Fault Warranty Program, saving the customer money and providing peace of mind when purchasing a used vehicle.",
  },
  {
    image: bellImg,
    titlee_arabic: "مؤشر نتيجة الفحص",
    title: "Inspection Result Indicator",
    descriptione_arabic:
      "مؤشر لنتيجة الفحص هذا المؤشر يقيم الحالة العامة للمركبة و يدل على اهلية المركبة للالتحاق ببرنامج ضمان الاعطال",
    description:
      "An indicator of the inspection result that assesses the vehicle's overall condition and eligibility for the Fault Warranty Program.",
  },
  {
    image: shieldImg,
    titlee_arabic: "البوم الصور",
    title: "Photo Album",
    descriptione_arabic:
      "فنيو الفحص في كارترست يلتقطون أكثر من 50 صورة بدقة عالية للمركبة، مما يساعد العميل في معرفة حالتها واتخاذ قرار شراء مدروس",
    description:
      "CarTrust inspectors take more than 50 high-resolution photos of the vehicle, helping the customer understand its condition and make an informed purchase decision.",
  },
  {
    image: analyticsImg,
    titlee_arabic: "خدمة منزلية",
    title: "Home Service",
    descriptione_arabic:
      "الخدمة المنزلية لفحص المركبات لتسهيل الخدمة في أي مكان يناسب العميل. تتيح الخدمة للعملاء حجز موعد فحص مركباتهم دون الحاجة  للذهاب إلى مركز الفحص",
    description:
      "Home vehicle inspection service to facilitate inspection anywhere convenient for the customer. This service allows customers to schedule an inspection without visiting the inspection center.",
  },
  {
    image: tasksImg,
    titlee_arabic: "تقرير موجز",
    title: "Summary Report",
    descriptione_arabic:
      "يتوفر تقرير موجز مدمج في تقرير كارترست والذي يوفر معلومات كاملة عن تاريخ المركبة يساعد في معرفة ، تاريخ قراءة العداد والكثير من المعلومات المهمة",
    description:
      "A summary report integrated into the CarTrust report provides comprehensive vehicle history information, including odometer readings and other vital details.",
  },
];


export const featuresData2: FeatureType[] = [
  {
    image: logo1,
    title_arabic: "شمولية الفحص",
    title: "Comprehensive Inspection",
    description_arabic:
      "يسعدنا بأننا حصلنا على شهادة من مركز الاعتماد الخليجي (GAC). هذه الشهادة المرموقة تعكس التزامنا بالتميّز والجودة والموثوقية في جميع جوانب عملنا",
    description:
      "We are pleased to have received a certificate from the Gulf Accreditation Center (GAC). This prestigious certification reflects our commitment to excellence, quality, and reliability in all aspects of our work.",
  },
  {
    image: logo2,
    title_arabic: "خدمة منزلية",
    title: "Home Service",
    description_arabic:
      "نفخر بالتزامنا بالتميّز والجودة والسلامة. ولذلك، نحن سعداء بالإعلان عن حصولنا على شهادة من TÜV، وهي مؤسسة عالمية معروفة في مجال الفحص التقني والتصديق",
    description:
      "We take pride in our commitment to excellence, quality, and safety. Therefore, we are pleased to announce that we have received certification from TÜV, a globally recognized institution in technical inspection and certification.",
  },
  {
    image: logo3,
    title_arabic: "خدمة منزلية",
    title: "Home Service",
    description_arabic:
      "نفخر بكوننا جهة معتمدة من المركز السعودي للاعتماد حيث يعد الجهة الوطنية المخولة بشكل رسمي لتقديم خدمات اعتماد لجهات تقويم المطابقة في المملكة والارتقاء بكفاءتها",
    description:
      "We are proud to be accredited by the Saudi Accreditation Center, the official national body authorized to provide accreditation services to conformity assessment bodies in the Kingdom and enhance their efficiency.",
  },
];


export const featuresData3: FeatureType[] = [
  {
    image: logo11,
    title_arabic: "اضافة تفاصيل المركبة",
    title: "Add Vehicle Details",
    description_arabic: 
      "اختيار الشركة المصنعة، الموديل وسنة الصنع. اضافة معلومات لوحة المركبة المراد تفعيل ضمان عليها",
    description: 
      "Select the manufacturer, model, and year of manufacture. Add the vehicle's plate information to activate the warranty.",
  },
  {
    image: logo12,
    title_arabic: "تعبئة معلوماتك",
    title: "Fill in Your Information",
    description_arabic: 
      "ادخال معلوماتك الشخصية المراد ربط الضمان به حسب نظام شركات التأمين المقدمة لخدمة ضمان الاعطال",
    description: 
      "Enter your personal information to link the warranty according to the system of insurance companies offering the breakdown warranty service.",
  },
  {
    image: logo13,
    title_arabic: "اختيار الباقة المفضلة",
    title: "Choose Your Preferred Package",
    description_arabic: 
      "بعد ادخال معلومات المركبة يتم اختيار الباقة بما يتناسب مع احتياج العميل بعد مشاهدة التغطيات لكل باقة",
    description: 
      "After entering the vehicle information, select the package that suits the customer's needs after reviewing the coverage for each package.",
  },
  {
    image: logo14,
    title_arabic: "اختيار موعد الفحص",
    title: "Choose an Inspection Date",
    description_arabic: 
      "يتم اختيار اقرب موعد يناسب العميل للفحص والتأكد من سلامة المركبة قبل تقديم طلب الضمان من شركة التأمين",
    description: 
      "Choose the earliest available date for inspection to ensure the vehicle's condition before submitting a warranty request to the insurance company.",
  },
];


export const workData: WorkType[] = [
  {
    title: "Powerful project management",
  },
  {
    title: "Transparent work management",
  },
  {
    title: "Manage work & focus on the most important tasks",
  },
  {
    title: "Track your progress with interactive charts",
  },
  {
    title: "Easiest way to track time spent on tasks",
  },
];

export const useCasesData: UseCaseType[] = [
  {
    icon: StarIcon,
    title: "Project Management",
    description:
      "Vestibulum nunc lectus auctor quis. Natoque lectus tortor lacus, eu. Nunc feugiat nisl maecenas nulla hac morbi. Vitae, donec facilisis sed nunc netus. Venenatis posuere faucibus enim est. Vel dignissim morbi blandit morbi tellus. Arcu ullamcorper quis enim.",
    image: useCase1Img,
  },
  {
    icon: BriefcaseAlt2ICon,
    title: "Remote Work",
    description:
      "Ut ornare quisque blandit condimentum nec viverra habitant non. Consequat id leo bibendum enim, fringilla pulvinar volutpat fames. Ut fames ut sed lobortis nulla. In dui enim et pharetra gravida diam semper lectus tellus. Faucibus mi convallis.",
    image: useCase2Img,
  },
  {
    icon: RocketIcon,
    title: "Product Release",
    description:
      "Fames netus pretium massa lobortis sit metus et. Sit risus odio fermentum hac fames enim, ac, in. Neque lacus, consequat hendrerit potenti in tristique dictum. Tortor nec lorem et venenatis facilisis risus viverra nunc, eu. Eget suscipit vel amet.",
    image: useCase3Img,
  },
  {
    icon: BatChartAlt2Icon,
    title: "Campaign Planning",
    description:
      "Diam bibendum scelerisque vel mauris magna. Iaculis interdum tincidunt eget nibh purus. Aliquet at sit morbi nisi volutpat fermentum scelerisque. Enim tincidunt eros sodales integer sapien. Eu ullamcorper egestas odio nunc faucibus. Sapien eu nunc sed facilisi luctus ac.",
    image: useCase4Img,
  },
];

const usersData = [
  {
    image: avatar14Img,
    name: "Annette Black",
    role: "Strategic Advisor at Company LLC",
  },
  {
    image: avatar13Img,
    name: "Ralph Edwards",
    role: "Head of Marketing at Lorem Ltd.",
  },
  {
    image: avatar11Img,
    name: "Darrell Steward",
    role: "Project Manager at Ipsum Ltd.",
  },
];
export const testimonialData: TestimonialType[] = [
  {
    comment:
      "Mi semper risus ultricies orci pulvinar in at enim orci. Quis facilisis nunc pellentesque in ullamcorper sit. Lorem blandit arcu sapien, senectus libero, amet dapibus cursus quam. Eget pellentesque eu purus volutpat adipiscing malesuada.",
    user: usersData[0],
  },
  {
    comment:
      "Vestibulum nunc lectus auctor quis. Natoque lectus tortor lacus, eu. Nunc feugiat nisl maecenas nulla hac morbi. Vitae, donec facilisis sed nunc netus. Venenatis posuere faucibus enim est. Vel dignissim morbi blandit morbi tellus.",
    user: usersData[1],
  },
  {
    comment:
      "Ac at sed sit senectus massa. Massa ante amet ultrices magna porta tempor. Aliquet diam in et magna ultricies mi at. Lectus enim, vel enim egestas nam pellentesque et leo. Elit mi faucibus laoreet aliquam pellentesque sed aliquet integer massa.",
    user: usersData[2],
  },
];

export const workToolsData: WorkToolType[] = [
  {
    image: googleImg,
  },
  {
    image: zoomImg,
  },
  {
    image: slackImg,
  },
  {
    image: gmaillImg,
  },
  {
    image: trelloImg,
  },
  {
    image: mailchimpImg,
  },
  {
    image: dropboxIMg,
  },
  {
    image: evernoteImg,
  },
];
