import type {
  AwardsType,
  BenefitType,
  SocialNetworkType,
  TeamMemberType,
  TestimonialType,
  UserType,
} from "./type";

import TwitterIcon from "@iconify-icons/ri/twitter-x-fill";
import LinkedinIcon from "@iconify/icons-bx/bxl-linkedin";

import DribbleIcon from "@iconify/icons-bx/bxl-dribbble";
import SnapChatIcon from "@iconify/icons-bx/bxl-snapchat";
import BehanceIcon from "@iconify/icons-bx/bxl-behance";
import StackOverflowIcon from "@iconify/icons-bx/bxl-stack-overflow";
import GithubIcon from "@iconify/icons-bx/bxl-github";
import FacebookIcon from "@iconify/icons-bx/bxl-facebook";
import InstagramIcon from "@iconify/icons-bx/bxl-instagram";
import YoutubeIcon from "@iconify/icons-bx/bxl-youtube";

export {
  LinkedinIcon,
  TwitterIcon,
  DribbleIcon,
  BehanceIcon,
  StackOverflowIcon,
  GithubIcon,
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
};

import ideaImg from "../../../assets/images/landing/digital-agency/icons/idea.svg";
import awardImg from "../../../assets/images/landing/digital-agency/icons/award.svg";
import teamImg from "../../../assets/images/landing/digital-agency/icons/team.svg";

import awardImg1 from "../../../assets/images/landing/digital-agency/awards/award-1.png";
import awardImg2 from "../../../assets/images/landing/digital-agency/awards/award-2.png";
import awardImg3 from "../../../assets/images/landing/digital-agency/awards/award-3.png";
import awardImg4 from "../../../assets/images/landing/digital-agency/awards/award-4.png";
import awardImg5 from "../../../assets/images/landing/digital-agency/awards/award-5.png";
import awardImg6 from "../../../assets/images/landing/digital-agency/awards/award-6.png";

import clientImg from "../../../assets/images/team/no_image.jpeg";

import team1Img from "../../../assets/images/team/ammar.png";
import team2Img from "../../../assets/images/team/khayatt.png";
import team3Img from "../../../assets/images/team/ayman.png";
import team4Img from "../../../assets/images/team/raed.png";
import team5Img from "../../../assets/images/team/nujud.png";
import team6Img from "../../../assets/images/team/Mohamed_khair.png";
import team7Img from "../../../assets/images/team/hussein.png";
import team8Img from "../../../assets/images/team/faisal-haydar.png";
import team9Img from "../../../assets/images/team/Ahmed_Siddiq.png";

export const benefitsData: BenefitType[] = [
  {
    image: ideaImg,
    title: "Professional Team",
    description:
      "CarTrust's team consists of years of experience in the automotive sector and vehicle data.",
    title_arabic: "فريق احترافي",
    description_arabic:
      "فريق كارترست مكون من خبرات ممتدة لسنوات في قطاع المركبات وعالم بيانات المركبات",
  },
  {
    image: awardImg,
    title: "Multiple Awards",
    description:
      "CarTrust has received multiple awards, including the national award for the automotive sector from PR Arabia.",
    title_arabic: "جوائز عديدة",
    description_arabic:
      "كارترست حاصلة على جوائز عديدة منها الجائزة الوطنية لقطاع المركبات من بي ار اريبيا",
  },
  {
    image: teamImg,
    title: "Creative Solutions",
    description:
      "All CarTrust solutions are built on advanced creative principles that serve the quality of the automotive sector and protect the client through a unique and advanced experience.",
    title_arabic: "حلول ابداعية",
    description_arabic:
      "جميع حلول كارترست مبنية على أسس ابداعية متقدمة تخدم جودة قطاع المركبات وحماية العميل من خلال تجربة فريدة ومتقدمة",
  },
];


export const awardsData: AwardsType[] = [
  {
    image: awardImg1,
    title: "Startup Competition",
  },
  {
    image: awardImg2,
    title: "مسك Misk",
  },
  {
    image: awardImg3,
    title: "MEA Business",
  },
  {
    image: awardImg4,
    title: "إرباكـ",
  },
  {
    image: awardImg5,
    title: "MISK ACCELERATOR",
  },
  {
    image: awardImg6,
    title: "PR ARABIA",
  },
];

const userData: UserType[] = [
  {
    image: clientImg,
    name: "يوسف النعمي",
    role: "مدير تنفيذي",
  },
  {
    image: clientImg,
    name: "شذى الحربي",
    role: "عميل",
  },
  {
    image: clientImg,
    name: "عبدالله محبوب",
    role: "عميل",
  },
];

export const testimonialData: TestimonialType[] = [
  {
    comment:
      "خدمات كارترست دائماً مميزة خصوصاً للشركات. تعاملت معهم في خدمات الفحص المتنقل وكانت ولازالت مميزة وجودة التقرير التقني المقدم لم نجد له مثيل",
    user: userData[0],
  },
  {
    comment:
      "تطبيق جميل  ودقة في السعر المقدم من خدمة التسعيرتغنيني عن النزول للسوق والسؤال عن سعر سيارتي او سؤال شخص من الممكن ينقصني ويبخس سيارتي. تجربة جميلة وسيتم تجربة خدمة الفحص وباقي الخدمات",
    user: userData[1],
  },
  {
    comment:
      "الخدمة كانت رائعة  جداً واختصرت علي كثيير من الوقت والجهد. جودة التقرير كانت غير متوقعة ماشاءالله والشباب يعطيهم العافيه ماقصرو جوني في الموعد المحدد ووصلني التقرير المفصل كلنك ومن خلال التطبيق. كارترست غيرت مفاهيم الفحص في البلد",
    user: userData[2],
  },
];

export const teamMemberData: TeamMemberType[] = [
  {
    image: team1Img,
    name: "Ammar  Toonsi",
    role: "CEO",
    email: "ammar@cartrust.sa",
    socialProfile: {
      icon: [FacebookIcon, LinkedinIcon, TwitterIcon],
    },
  },

  {
    image: team2Img,
    name: "Mamdouh  Khayyat",
    role: "Executive Consultant",
    email: "mamdouh@cartrust.sa",
    socialProfile: {
      icon: [FacebookIcon, LinkedinIcon, TwitterIcon],
    },
  },
  {
    image: team3Img,
    name: "Ayman Shams",
    role: "Business Development Director",
    email: "ayman@cartrust.sa",
    socialProfile: {
      icon: [FacebookIcon, DribbleIcon, LinkedinIcon],
    },
  },
  {
    image: team4Img,
    name: "Raed Hejazi",
    role: "Administrative Affairs Director",
    email: "raed@cartrust.sa",
    socialProfile: {
      icon: [FacebookIcon, TwitterIcon, LinkedinIcon],
    },
  },
  {
    image: team8Img,
    name: "Faisal Al-Rowaily",
    role: "Senior Officer Operations",
    email: "ali@cartrust.sa",
    socialProfile: {
      icon: [FacebookIcon, LinkedinIcon, TwitterIcon],
    },
  },
  {
    image: team7Img,
    name: "Hussain Al Ahdal",
    role: "Technology Manager",
    email: "hussain@cartrust.sa",
    socialProfile: {
      icon: [FacebookIcon, StackOverflowIcon, GithubIcon],
    },
  },
  {
    image: team9Img,
    name: "Ahmed Abu Yousuf",
    role: "Senior Mobile App Developer",
    email: "ahmed.yousuf@cartrust.sa",
    socialProfile: {
      icon: [FacebookIcon, LinkedinIcon, TwitterIcon],
    },
  },
  {
    image: team5Img,
    name: "Nujud Sabano",
    role: "Data Analyst",
    email: "nujud@cartrust.sa",
    socialProfile: {
      icon: [FacebookIcon, BehanceIcon, DribbleIcon],
    },
  },
  {
    image: team6Img,
    name: "Mohammed Khair",
    role: "Business Development Officer",
    email: "",
    socialProfile: {
      icon: [FacebookIcon, DribbleIcon, BehanceIcon],
    },
  },
];

const socialId: string[] = ["cartrustksa", "@cartrustksa", "cartrustksa"];
export const socialNetworkData: SocialNetworkType[] = [
  {
    icon: FacebookIcon,
    title: "Facebook",
    socialId: socialId[0],
    socialLink: "https://www.facebook.com/profile.php?id=61556080312974",
  },
  {
    icon: InstagramIcon,
    title: "Instagram",
    socialId: socialId[1],
    socialLink: "https://www.instagram.com/cartrustksa",
  },
  {
    icon: TwitterIcon,
    title: "X",
    socialId: socialId[1],
    socialLink: "https://twitter.com/CarTrustksa",
  },
  {
    icon: LinkedinIcon,
    title: "LinkedIn",
    socialId: socialId[2],
    socialLink: "https://sa.linkedin.com/company/cartrustksa",
  },
  {
    icon: YoutubeIcon,
    title: "YouTube",
    socialId: socialId[0],
    socialLink: "https://www.youtube.com/@cartrust5834",
  },
  {
    icon: SnapChatIcon,
    title: "SnapChat",
    socialId: socialId[0],
    socialLink: "https://www.snapchat.com/add/cartrustksa",
  },
];
